<template>
    <div>
        <!-- 轮播 -->
        <div class="banner-wrap">            
            <div class="swiper-container" ref="banner">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in imgUrl" :key="index" >
                        <img :src="item.pic_url" alt="" @click="goUrl(item.link)">
                    </div>
                    <!-- <div class="swiper-slide">
                        <img :src="imgUrl" alt="">
                    </div> -->
                </div>
                <div class="swiper-pagination" ref="swiper-pagination"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import Swiper from '../assets/js/swiper';
    import {mapState} from 'vuex';
    import { getlunbos ,getwes} from '../api/index'
    export default {
        data() {
            return {
                imgUrl:[],
                plate_id:'',
            }
        },
        computed: {
            ...mapState(['routeIndex','routeId'])
        },
        created() {            
            this.setUrl()
            this.getlunbos()
        },
        watch: {
            routeIndex(toVal) {
                // console.log(toVal)
                this.setUrl();
                this.getlunbos()
            },
            routeId(){
                // console.log('执行了')
                this.setUrl();
                this.getlunbos()
            }
        },
        methods: {
            goUrl(url){
                if (url) {
                    window.open(url)                     
                }else{
                    return false;
                }
            },
            getlunbos(){
                getlunbos({
                    plate_id:this.plate_id
                }).then((res)=>{
                    // console.log(res);
                    if (res.code==200) {
                        this.imgUrl=res.data;
                        this.$nextTick(()=>{
                            let mySwiper = new Swiper(this.$refs['banner'], {
                                // autoplay: 1000,
                                autoplayDisableOnInteraction : false,
                                // loop:true,
                                pagination : this.$refs['swiper-pagination'],
                                paginationClickable :true,
                            })
                            this.$refs['banner'].onmouseenter = function () {
                                mySwiper.stopAutoplay();
                            };
                            this.$refs['banner'].onmouseleave = function () {
                                mySwiper.startAutoplay();
                            }
                        });
                    }
                })
            },
            setUrl(){
                // console.log(this.$route.name);
                let name = this.$route.name;
                if (name == "kjdspt") {
                    // this.imgUrl = require("../assets/images/banner/kjdspt.png")
                    this.plate_id=3
                } else if (name == "wmyxgj") {
                    // this.imgUrl = require("../assets/images/banner/kjdspt.png")
                    this.plate_id=4
                } else if (name == "hwcyx") {
                    // this.imgUrl = require("../assets/images/banner/hwcyx.png")
                    this.plate_id=6
                } else if (name == "jksc") {
                    // this.imgUrl = require("../assets/images/banner/jksc.png")
                    this.plate_id=7
                } else if (name == "zbcg") {
                    // this.imgUrl = require("../assets/images/banner/zbcg.png")
                    this.plate_id=5
                } else if (name == "gjhz") {
                    // this.imgUrl = require("../assets/images/banner/gjhz.png")
                    this.plate_id=8
                } else if (name == "gqdj") {
                    // this.imgUrl = require("../assets/images/banner/gqdj.png")
                    this.plate_id=9
                }else if (name == "gwkhxxk") {
                    // this.imgUrl = require("../assets/images/banner/gwkhxxk.png")
                    this.plate_id=10
                }else if (name == "wmzhfw") {
                    // this.imgUrl = require("../assets/images/banner/jckdl.png")
                    this.plate_id = this.routeId 
                }else if (name == "cyppch") {
                    // this.imgUrl = require("../assets/images/banner/ppch.png")
                    this.plate_id=20
                }else if (name == "hwzy") {
                    // this.imgUrl = require("../assets/images/banner/hwzy.png")
                    this.plate_id=24
                }else if (name == "bdswfw") {
                    // this.imgUrl = require("../assets/images/banner/bdswfw.png")
                    this.plate_id=25
                }else if (name == "partner") {
                    // this.imgUrl = require("../assets/images/banner/hzhb.png")
                    this.plate_id=26
                }else if (name == "platform") {
                    // this.imgUrl = require("../assets/images/banner/hzhb.png")
                    this.plate_id='ptjs'
                }else if (name == "contact_us") {
                    // this.imgUrl = require("../assets/images/banner/hzhb.png")
                    this.plate_id='lxwm'
                }

            }
        },
        
    }
</script>

<style lang="less" scoped>
@import "../assets/css/swiper.css" ;
// 轮播
.banner-wrap{width: 100%;height: 400px;}
.banner-wrap .swiper-container{width: 100%;height: 100%;}
.banner-wrap .swiper-slide{
    width: 100%;height: 100%;
    img{width: 100%;height: 100%;object-fit: cover;cursor: pointer;}
}
/deep/ .banner-wrap .swiper-pagination-bullet{background: #fff;opacity: .5;}
/deep/ .banner-wrap .swiper-pagination-bullet-active{width: 20px;border-radius: 30px;background: #fff;opacity: 1;}


</style>